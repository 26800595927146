<template>
  <v-container fluid class="ml-0">
    <v-row
      ><v-col cols="auto">
        <v-row class="mt-1 mx-0">
          <v-combobox
            v-model="viewSelected"
            :items="AllViewNames"
            label="Select View Structure"
            outlined
            dense
            class="mr-1 my-1"
          ></v-combobox>
          <v-btn color="primary" class="ma-1" @click="LoadView">
            <v-icon left> mdi-download</v-icon>
            Load
          </v-btn>

          <v-btn color="primary" class="ma-1" @click="SaveView">
            <v-icon left> mdi-cloud-upload-outline </v-icon>
            Save
          </v-btn>

          <v-btn color="primary" class="my-1" @click="DiscardViewEdits">
            <v-icon left> mdi-close </v-icon>
            Discard
          </v-btn>
          <v-spacer />
        </v-row>

        <v-row class="mb-2 mx-1">
          Load a view structure, then edit the JSON code below and save. Saving commits
          the structure to the current page, but not the server. Uploading changes (button
          at bottom of page) will permanently commit the edits, and push them out to ALL
          USERS. Use with caution and only after thorough testing of any edits made.
          Errors can result in failure of the website.
        </v-row>
        <v-row>
          <v-jsoneditor v-model="jsonInput" height="80vh" :options="jsonoptions" />
        </v-row>
        <v-row v-if="madeChanges">
          <v-btn color="primary" class="ma-1" @click="UploadChanges">
            <v-icon left> mdi-cloud-upload-outline </v-icon>
            Upload Changes (Warning)
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import VJsoneditor from "v-jsoneditor/src/index";
import dataloader from "@/api/dataloader.js";

export default {
  components: {
    VJsoneditor,
  },

  data: () => ({
    viewSelected: "",
    isBusy: false,
    madeChanges: false,
    jsonInput: {
      help: "please load a suitable view entry to edit",
    },
    jsonoptions: { mode: "code" },
    currentViewEdited: "",
  }),
  props: {
    viewname: { default: "none", type: String }, // full - all groups. none = zero groups, one = just the group containing the tools
  },
  computed: {
    ViewStructures() {
      return this.$store.getters.getViewStructure;
    },

    AllViewNames() {
      var typeList = [];
      for (const [entryname] of Object.entries(this.ViewStructures)) {
        typeList.push(entryname);
      }
      return typeList;
    },

    authObject() {
      return {
        editEnable: this.$store.getters["menu/canEditTools"],
        permissions: this.$store.getters["user/permissions"],
      };
    },
  },
  methods: {
    LoadView() {
      if (this.viewSelected === "") return;
      const thisView = this.ViewStructures[this.viewSelected];
      this.currentViewEdited = this.viewSelected;
      this.jsonInput = JSON.parse(JSON.stringify(thisView));
    },
    DiscardViewEdits() {
      this.viewSelected = "";
      this.currentViewEdited = "";
      this.jsonInput = { help: "please load a suitable tool entry to edit" };
    },
    SaveView() {
      console.log("SAVE " + this.viewSelected);
      if (this.currentViewEdited == "") return;

      var newViewStructure = JSON.parse(JSON.stringify(this.ViewStructures));
      newViewStructure[this.currentViewEdited] = this.jsonInput;
      this.$store.commit("setViewStructure", newViewStructure);
      if (this.currentViewEdited == "defaultMenuList") {
        this.$store.commit("menu/setMenuList", newViewStructure.defaultMenuList);
      }
      this.madeChanges = true;
    },

    async UploadChanges() {
      this.isBusy = true;
      await dataloader.setViewStructure(this.ViewStructures);
      this.isBusy = false;
      this.madeChanges = false;
    },
  },
};
</script>

<style>
div.jsoneditor-menu {
  background-color: #455a64;
  border: thin solid #263238;
}
div.jsoneditor {
  border: thin solid #263238;
}
a.jsoneditor-poweredBy {
  color: #eceff1;
}
</style>
